<template>
  <Popup ref="popupRef" width="500px" title="冻结账户" @confirm="confirm" @close="resetForm">
    <el-input
      type="textarea"
      :rows="8"
      :maxlength="150"
      show-word-limit
      placeholder="请输入冻结理由"
      v-model="inputValue">
    </el-input>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
import { userStatusOperateApi } from '@/api/admin.js'
export default {
  components: {
    Popup
  },
  data() {
    return {
      inputValue: '',
      uid: ''
    }
  },
  methods: {
    open(option) {
      this.uid = option.uid
      this.$refs.popupRef.open()
    },
    close() {
      this.$refs.popupRef.close()
    },
    resetForm() {
      this.inputValue = ''
    },
    confirm() {
      if(!this.inputValue.trim()) return this.$tips({message: '请输入冻结理由', type:'warning'})
      userStatusOperateApi({
        uid: this.uid,
        act: 1,
        type: 2, // 1用户 2 商户
        reason: this.inputValue.trim()
      }).then(res => {
        this.$tips({message: res.msg, type:'success'})
        this.$emit('update')
        this.close()
      }).catch(err => {
        this.$tips({message: err, type:'error'})
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>